/*!

=========================================================
* Paper Kit React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import { Button, Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container } from "reactstrap";
import Logout from "../Logout/logout";

function IndexNavbar({ user }) {
  // const [navbarColor, setNavbarColor] = React.useState("");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  // React.useEffect(() => {
  //   const updateNavbarColor = () => {
  //     if (document.documentElement.scrollTop > 299 || document.body.scrollTop > 299) {
  //       setNavbarColor("");
  //     } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
  //       setNavbarColor("navbar-transparent");
  //     }
  //   };

  //   window.addEventListener("scroll", updateNavbarColor);

  //   return function cleanup() {
  //     window.removeEventListener("scroll", updateNavbarColor);
  //   };
  // });
  return (
    <Navbar className=" bg-dark-blue " expand="lg">
      {/* <Navbar className={classnames("fixed-top bg-primary", navbarColor)} expand="lg"> */}
      <Container>
        <div className="navbar-translate align-items-center">
          <NavbarBrand className="navbar-height " data-placement="bottom" href="/">
            <img className="brand-logo" src="./images/logo.png" />
            {/* Turnos */}
          </NavbarBrand>
          {user ? <NavbarBrand style={{ color: "white" }}>{user?.nombre + " " + user?.apellido}</NavbarBrand> : ""}
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
          <Nav navbar>
            {user && user.profile.isAdmin ? (
              <>
                <NavItem>
                  <NavLink href="/usuarios" title="Usuarios">
                    <i className="fa fa-users mr-1" />
                    {/* <p className="d-lg-none">Locadores</p> */}
                    Usuarios
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/salas" title="Salas">
                    <i className="fa fa-building" />
                    {/* <p className="d-lg-none">Locadores</p> */}
                    Salas
                  </NavLink>
                </NavItem>
              </>
            ) : (
              ""
            )}
            <NavItem>
              <NavLink href="/reservar" title="Reservar">
                <i className="fa fa-ticket mr-1" />
                {/* <p className="d-lg-none">Locadores</p> */}
                {!user.profile.isAdmin && <>Reservar</>}
                {user.profile.isAdmin && <>Reservar</>}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/miseventos" title="Mis Eventos">
                <i className="fa fa-calendar-check-o mr-1" />
                {!user.profile.isAdmin && <>Mis Eventos</>}
                {user.profile.isAdmin && <>Eventos</>}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/invitar" title="Invitar">
                <i className="fa fa-glass mr-1" />
                <>Invitar</>
              </NavLink>
            </NavItem>
            {user && user.puedelistar && (
              <NavItem>
                <NavLink href="/invitados" title="Invitados">
                  <i className="fa fa-list-alt mr-1" />
                  <>Invitados</>
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink href="/logout" title="Desconectar">
                <i className="fa fa-sign-out mr-1" />
                Desconectar
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink data-placement="bottom" href="https://www.github.com/CreativeTimOfficial/paper-kit-react?ref=creativetim" target="_blank" title="Star on GitHub">
                <i className="fab fa-github" />
                <p className="d-lg-none">GitHub</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://demos.creative-tim.com/paper-kit-react/#/documentation?ref=pkr-index-navbar" target="_blank">
                <i className="nc-icon nc-book-bookmark" /> Documentation
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <Button className="btn-round" color="danger" href="https://www.creative-tim.com/product/paper-kit-pro-react?ref=pkr-index-navbar" target="_blank">
                <i className="nc-icon nc-spaceship"></i> Upgrade to Pro
              </Button>
            </NavItem> */}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
