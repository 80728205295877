import "./App.css";
import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { InstallPWA } from "./installPWA/installPWA";
import { Container } from "reactstrap";
import { getUser } from "./services/userService";
import jwtDecode from "jwt-decode";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Home from "./components/Home/home";
import Salas from "./components/Salas/salas";
import SalasForm from "./components/Salas/salasForm";
import Users from "./components/Users/users";
import UsersForm from "./components/Users/usersForm";
import Invitar from "components/Invitar/invitar";
import Invitados from "components/Invitados/invitados";
import Login from "./components/Login/login";
import Logout from "./components/Logout/logout";
import Reservar from "components/Reservar/reservar";
import Recuperar from "./components/Recuperar/recuperar";
import ResetPassword from "components/Recuperar/resetpassword";
import PreLoader from "./components/preloader/preloader";
import { toastBigError } from "./components/utils/toasts";
import { regSw, subscribe } from "./registerSW";

const App = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  const loading = () => {
    setIsLoading(true);
  };

  const doneLoading = () => {
    setIsLoading(false);
  };

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) fetchUser(token);
  }, []);

  const fetchUser = async (jwt) => {
    const decoded = jwt ? jwtDecode(jwt) : null;
    const user = await getUser(decoded.user._id);
    if (!user || user.length === 0) {
      localStorage.removeItem("token");
    }
    if (user === "NETWORK ERROR") {
      toastBigError("ERROR DE CONEXION");
      localStorage.removeItem("token");
    }
    setUser(user);

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    const registerAndSubscribe = async (user) => {
      try {
        const serviceWorkerReg = await regSw();
        let subscription = await subscribe(serviceWorkerReg, user);
      } catch (error) {
        console.log(error);
      }
    };

    registerAndSubscribe(user);
  };

  return !token ? (
    <>
      <InstallPWA />
      <Container className="main-container">
        <Switch>
          <Route path="/login" render={(props) => <Login onLoading={loading} onDoneLoading={doneLoading} />} />
          <Route path="/recuperar" render={(props) => <Recuperar onLoading={loading} onDoneLoading={doneLoading} />} />
          <Route path="/resetpassword/:id/:token" render={(props) => <ResetPassword onLoading={loading} onDoneLoading={doneLoading} />} />
          <Redirect to="/login" />
        </Switch>
      </Container>
    </>
  ) : !user ? (
    <>{isLoading && <PreLoader onLoading={loading} onDoneLoading={doneLoading} />}</>
  ) : user.needsreset ? (
    <>
      <Redirect to={`/resetpassword/${user._id}/${token}`} />
      <Route path="/resetpassword/:id/:token" render={(props) => <ResetPassword user={user} onLoading={loading} onDoneLoading={doneLoading} />} />
      <Route path="/login" render={(props) => <Login onLoading={loading} onDoneLoading={doneLoading} />} />
    </>
  ) : (
    <>
      <InstallPWA />
      {isLoading && <PreLoader onLoading={loading} onDoneLoading={doneLoading} />}
      {!isLoading && (
        <>
          <IndexNavbar user={user} />
          {/* <Container className="main-container"> */}
          <Switch>
            <Redirect from="/login" exact to="/home" />
            <Route path="/home" render={(props) => <Home user={user} onLoading={loading} onDoneLoading={doneLoading} />} />
            <Route path="/salas/:id" render={(props) => <SalasForm props={props} onLoading={loading} onDoneLoading={doneLoading} />} />
            <Route path="/salas" render={(props) => <Salas onLoading={loading} onDoneLoading={doneLoading} />} />
            <Route path="/usuarios/:id" render={(props) => <UsersForm props={props} onLoading={loading} onDoneLoading={doneLoading} />} />
            <Route path="/usuarios" render={(props) => <Users onLoading={loading} onDoneLoading={doneLoading} />} />
            <Route path="/logout" render={(props) => <Logout onLoading={loading} onDoneLoading={doneLoading} />} />
            <Route path="/reservar" render={(props) => <Reservar user={user} view="calendar" onLoading={loading} onDoneLoading={doneLoading} />} />
            <Route path="/invitar" render={(props) => <Invitar user={user} onLoading={loading} onDoneLoading={doneLoading} />} />
            <Route path="/invitados" render={(props) => <Invitados user={user} onLoading={loading} onDoneLoading={doneLoading} />} />
            <Route path="/miseventos" render={(props) => <Reservar user={user} view="list" onLoading={loading} onDoneLoading={doneLoading} />} />
            <Redirect from="/" exact to="/home" />
          </Switch>
          {/* </Container> */}
        </>
      )}
    </>
  );

  // token ? (
  //   <>
  //     <InstallPWA />
  //     {isLoading && <PreLoader onLoading={loading} onDoneLoading={doneLoading} />}
  //     {!isLoading && (
  //       <>
  //         <IndexNavbar user={user} />
  //         <Container className="main-container">
  //           <Switch>
  //             <Redirect from="/login" exact to="/home" />
  //             <Route path="/home" render={(props) => <Home user={user} onLoading={loading} onDoneLoading={doneLoading} />} />
  //             <Route path="/salas/:id" render={(props) => <SalasForm props={props} onLoading={loading} onDoneLoading={doneLoading} />} />
  //             <Route path="/salas" render={(props) => <Salas onLoading={loading} onDoneLoading={doneLoading} />} />
  //             <Route path="/usuarios/:id" render={(props) => <UsersForm props={props} onLoading={loading} onDoneLoading={doneLoading} />} />
  //             <Route path="/usuarios" render={(props) => <Users onLoading={loading} onDoneLoading={doneLoading} />} />
  //             <Route path="/logout" render={(props) => <Logout onLoading={loading} onDoneLoading={doneLoading} />} />
  //             <Route path="/reservar" render={(props) => <Reservar user={user} view="calendar" onLoading={loading} onDoneLoading={doneLoading} />} />
  //             <Route path="/miseventos" render={(props) => <Reservar user={user} view="list" onLoading={loading} onDoneLoading={doneLoading} />} />
  //             <Redirect from="/" exact to="/home" />
  //           </Switch>
  //         </Container>
  //       </>
  //     )}
  //   </>
  // ) : (
  //   <>
  //     <InstallPWA />
  //     <Container className="main-container">
  //       <Switch>
  //         <Route path="/login" render={(props) => <Login onLoading={loading} onDoneLoading={doneLoading} />} />
  //         <Route path="/recuperar" render={(props) => <Recuperar onLoading={loading} onDoneLoading={doneLoading} />} />
  //         <Route path="/resetpassword" render={(props) => <ResetPassword onLoading={loading} onDoneLoading={doneLoading} />} />
  //         <Redirect to="/login" />
  //       </Switch>
  //     </Container>
  //   </>
  // );
};

export default App;
