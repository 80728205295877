import http from "./httpService";
import { getConfig } from "../config/config.js";

const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/invitaciones";

export async function getInvitacion(recordId) {
  try {
    const response = await http.get(serviceEndpoint + "/" + recordId);
    return response.data.record;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getInvitados() {
  try {
    const response = await http.get(serviceEndpoint + "/invitados");
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}
export async function getInvitaciones() {
  try {
    const response = await http.get(serviceEndpoint);
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function resendQR(data) {
  try {
    const response = await http.post(serviceEndpoint + "/acredita/" + data.token);
    return response;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function saveInvitacion(data, parentId) {
  // console.log(data);
  //   if (data._id) return await http.put(serviceEndpoint + "/" + data._id, data);
  //   else {
  // data.parentId = parentId;
  return await http.post(serviceEndpoint, data);
  //   }
}

// export async function deleteSala(recordId) {
//   return await http.delete(serviceEndpoint + "/" + recordId);
// }

// export async function getSalasSelect() {
//   let items = [];
//   try {
//     const response = await http.get(serviceEndpoint);
//     response.data.records.map((item) => {
//       items.push({ value: item._id, label: item.descripcion });
//     });
//     return items;
//   } catch (error) {
//     if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
//     else return [];
//   }
// }
