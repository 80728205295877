import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./eventCalendar.css";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import { Badge, Table, Label, Input, Button, Row, Col, Modal, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { toastBigError, toastBigInfo, toastBigSuccess, toastConfirm, toastTriState } from "components/utils/toasts";
import { capFirst } from "components/utils/capFirst";
import { getSala, getSalasSelect } from "services/salaService";
import { saveReserva, getReserva, getReservas, notifyReserva, getEventos, confirmaReserva } from "services/reservaService";
import { saveExcepcion } from "services/excepcionService";
import { getUsers } from "services/userService";
import { cleanString } from "components/utils/cleanString";
import Joi from "joi-browser";
import Switch from "react-bootstrap-switch";
import Select from "react-select";
import Toolbar from "react-big-calendar/lib/Toolbar";
import ReactDatetime from "react-datetime";
import moment from "moment";
import "moment/locale/es.js";
import events from "./events";
import { toastInfo } from "components/utils/toasts";
const { WAPP_NOTIFY, WAPP_NOTIFY_AGENTE } = require("../constants/constants");

const EventCalendar = ({ user, view, localizer, dayLayoutAlgorithm = "no-overlap" }) => {
  const today = new Date();
  const views = ["day", "work_week"];
  const mLocalizer = momentLocalizer(moment);
  const [usuarios, setUsuarios] = useState([]);
  const [sala, setSala] = useState(null);
  const [salas, setSalas] = useState();
  const [reserva, setReserva] = useState({});
  const [reservas, setReservas] = useState(events);
  const [selectTeam, setSelectTeam] = useState(false);
  const [modal, setModal] = useState(false);
  const [notice, setNotice] = useState(false);
  const [notify, setNotify] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentView, setCurrentView] = useState(Views.DAY);
  const [currentDate, setCurrentDate] = useState(today);
  const [esPropia, setEsPropia] = useState(false);
  const [timer, setTimer] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState({ path: "nombre", order: "asc" });
  const [refresh, setRefresh] = useState(false);
  const [showCancel, setShowCancel] = useState(false);

  moment.locale("es", {
    week: {
      dow: 1, // for starrting day from monday
    },
  });

  const schema = {
    titulo: Joi.string()
      .label("Título")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar un título" };
            default:
              return { message: "Debe ingresar un título" };
          }
        });
      }),
    detalle: Joi.string()
      .label("Participantes")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar cantidad de participantes" };
            default:
              return { message: "Debe ingresar cantidad de participantes" };
          }
        });
      }),
    day: Joi.number(),
    month: Joi.number(),
    year: Joi.number(),
    start: Joi.date(),
    end: Joi.date(),
    label: Joi.string(),
    semanal: Joi.boolean(),
  };

  const fetchSalas = async () => {
    const cacheSalas = await getSalasSelect();
    setSalas(cacheSalas);
  };

  const fetchReservas = async (t) => {
    if (view === "calendar") {
      if (sala) {
        const cacheReservas = await getReservas(sala, currentDate, currentView);
        if (cacheReservas === "NETWORKERROR") {
          toastBigError("Error de Conexion");
        } else setReservas(cacheReservas);
      }
    } else {
      const cacheReservas = await getEventos(user);
      setReservas(cacheReservas);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchSalas();
  }, []);

  useEffect(() => {
    fetchReservas();
    if (timer) clearInterval(timer);
    setTimer(() => setInterval(fetchReservas, 10000));
  }, [sala, currentDate, currentView, refresh, reserva]);

  const handleSelectEvent = useCallback((event) => {
    if (timer) clearInterval(timer);
    if (event.user._id === user._id) setEsPropia(true);
    else setEsPropia(false);
    const hoy = Date.now();
    const start = event.start;
    if (start < hoy && !user.profile.isAdmin) {
      toastBigError("No se puede selecionar una reserva que ya pasó");
    } else {
      setReserva(event);
      setNotice(true);
    }
  });

  const handleSelectSlot = useCallback(async (event) => {
    if (timer) clearInterval(timer);
    setErrors({});
    const hoy = Date.now();
    const start = event.start;
    const end = event.end;
    if (start < hoy && !user.profile.isAdmin) {
      toastBigError("No se puede reservar sala para una fecha pasada");
    } else {
      const month = start.getUTCMonth() + 1; //months from 1-12
      const day = start.getUTCDate();
      const year = start.getUTCFullYear();
      setReserva({
        start: start,
        end: end,
        day: day,
        month: month,
        year: year,
        semanal: false,
        titulo: "",
        detalle: "",
        label: capFirst(moment(start).format("dddd D [de] MMMM [de] YYYY")),
      });
      setModal(true);
    }
  });

  const handleChangeTime = (event) => {
    // console.log("changeTime");
    // console.log(event);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    // var backgroundColor = event.user === user._id ? "#64adcc" : "#ed7455";
    var backgroundColor = event.user._id === user._id ? (event.confirmada ? "#6bd098 " : "#fbc658") : "#66615b";
    var borderColor = event.user._id === user._id ? (event.confirmada ? "#28a745" : "#ffc107") : "#000000";
    var style = {
      backgroundColor: backgroundColor,
      borderColor: borderColor,
      color: "white",
      // borderRadius: '0px',
      // opacity: 0.8,
      // color: 'black',
      // border: '0px',
      // display: 'block'
    };
    return {
      style: style,
    };
  };

  var CustomToolbar = ({ handleChange }) => {
    return class BaseToolBar extends Toolbar {
      constructor(props) {
        super(props);
        // console.log(props);
      }
      handleDayChange = (event, mconte) => {
        setCurrentView(event.target.value);
        mconte(event.target.value);
      };
      handleNamvigate = (detail, elem) => {
        detail.navigate(elem);
      };

      render() {
        return (
          <Row className="mt-3 mb-3 mr-0 ml-0 full-width align-items-center" xs="3">
            <Col xs="4" className="p-0">
              <Button size="sm" className="btn-just-icon btn-toolbar-blue ml-1" type="button" onClick={() => this.handleNamvigate(this, "PREV")}>
                <i className="fa fa-arrow-left" />
              </Button>{" "}
              <Button size="sm" className="btn-round btn-toolbar-blue btn ml-1" type="button" onClick={() => this.handleNamvigate(this, "TODAY")}>
                Hoy
              </Button>
              <Button size="sm" className="btn-just-icon btn-toolbar-blue ml-1" type="button" onClick={() => this.handleNamvigate(this, "NEXT")}>
                <i className="fa fa-arrow-right" />
              </Button>
            </Col>
            <Col className="text-center align-items-center p-0" xs="4">
              <h6 className="mb-0">{this.props.label}</h6>
              {/* <h6 className="mb-0">{moment(this.props.date).format("YYYY-MM-DD")}</h6> */}
            </Col>
            <Col className="text-right p-0" xs="4">
              <Button size="sm" className="btn-round mr-1 btn-red" type="button" value="day" onClick={(e) => this.handleDayChange(e, this.view)}>
                Día
              </Button>
              <Button size="sm" className="btn-round mr-1 btn-red" type="button" value="work_week" onClick={(e) => this.handleDayChange(e, this.view)}>
                Sem.
              </Button>
            </Col>
          </Row>
        );
      }
    };
  };

  const handleNavigation = (date, view, action) => {
    // let cacheDate = moment(date);
    // console.log(action);
    // console.log(cacheDate.weekday());
    // if (cacheDate.weekday() === 6) {
    //   if (action === "NEXT") cacheDate.add(1, "days");
    //   if (action === "PREV") cacheDate.subtract(2, "days");
    // }
    // console.log("handdleNavigation");
    setCurrentDate(date);
    setCurrentView(view);
    //it returns current date, view options[month,day,week,agenda] and action like prev, next or today
  };

  const change = () => {
    console.log("change");
  };

  const validateProperty = (input, inputMode) => {
    let { name, value } = input;
    if (inputMode === "numeric") {
      const index = value.indexOf(" ");
      value = parseFloat(value.substr(value.indexOf(" ") + 1));
    }
    const object = { [name]: value };
    const checkSchema = { [name]: schema[name] };
    const { error } = Joi.validate(object, checkSchema);
    return error ? error.details[0].message : null;
  };

  const validateRules = () => {
    return null;
  };

  const validate = () => {
    let errors = {};
    const options = { abortEarly: false };
    // var { data } = this.state;
    Object.keys(reserva).map(function (key, index) {
      if ((key.toLowerCase().includes("dni") || key.toLowerCase().includes("documento")) && !key.toLowerCase().includes("tipo")) {
        const valor = reserva[key];
        const stripped = valor.replaceAll(".", "");
        if (stripped.length === 8) {
          const nuevoValor = stripped.substring(0, 2) + "." + stripped.substring(2, 5) + "." + stripped.substring(5, 8);
          reserva[key] = nuevoValor;
        }
        if (stripped.length === 7) {
          const nuevoValor = stripped.substring(0, 1) + "." + stripped.substring(1, 4) + "." + stripped.substring(4, 7);
          reserva[key] = nuevoValor;
        }
      }
      if (typeof reserva[key] === "object" && reserva[key] && reserva[key].value && typeof reserva[key].value === "string") {
        reserva[key] = reserva[key].value;
      }
    });
    let { error } = Joi.validate(reserva, schema, options);
    if (!error) {
      error = validateRules();
      if (!error) {
        Object.keys(reserva).map(function (key, index) {
          if (typeof reserva[key] === "object" && reserva[key] && reserva[key].value) reserva[key] = reserva[key].value;
        });
        return null;
      }
      error.map((error) => {
        toastBigError(error.message, 2500);
        return (errors[error.path] = error.message);
      });
      return errors;
    } else {
      error.details.map((validationError) => {
        return (errors[validationError.path[0]] = validationError.message);
      });
      return errors;
    }
  };

  const handleSearchQuery = (event) => {
    const input = event.currentTarget;
    setSearchQuery(input.value);
  };

  const handleChange = (event) => {
    const input = event.currentTarget;
    const inputMode = event.target.inputMode;
    const cacheErrors = { ...errors };
    const cacheReserva = { ...reserva };
    const errorMessage = validateProperty(input, inputMode);
    if (errorMessage) {
      cacheErrors[input.name] = errorMessage;
    } else delete cacheErrors[input.name];
    cacheReserva[input.name] = input.value;
    setReserva(cacheReserva);
    setErrors(cacheErrors);
  };

  const handleChangeSala = (selectedOption, input) => {
    setSala(selectedOption);
  };

  const handleChangeSemanal = () => {
    const cacheReserva = { ...reserva };
    cacheReserva["semanal"] = !cacheReserva["semanal"];
    setReserva(cacheReserva);
  };

  const handleReserva = async () => {
    const checkErrors = validate();
    const data = {};
    if (checkErrors) {
      setErrors(checkErrors);
      return;
    }
    setModal(false);
    data.sala = sala.value;
    data.titulo = reserva.titulo;
    data.detalle = reserva.detalle;
    data.start = reserva.start;
    data.end = reserva.end;
    data.semanal = reserva.semanal;
    const checkReservas = await getReservas(sala, currentDate, currentView);
    if (checkReservas === "NETWORK ERROR") toastBigError("Error de conexion!");
    else {
      const checkStart = moment(reserva.start);
      const checkEnd = moment(reserva.end);
      let seSolapa = false;
      checkReservas.forEach((item) => {
        const checkItemStart = moment(item.start);
        const checkItemEnd = moment(item.end);
        if (checkStart.isBetween(item.start, item.end)) seSolapa = true;
        if (checkEnd.isBetween(item.start, item.end)) seSolapa = true;
        if (checkStart.isSame(item.start)) seSolapa = true;
        if (checkEnd.isSame(item.end)) seSolapa = true;
        if (checkItemStart.isBetween(checkStart, checkEnd)) seSolapa = true;
        if (checkItemEnd.isBetween(checkStart, checkEnd)) seSolapa = true;
      });
      if (seSolapa) toastBigError("Tu reserva se superpone con otra!");
      else {
        try {
          const checkSala = await getSala(sala.value);
          if (!checkSala.requiereConfirmacion || user.profile.isAdmin) data.confirmada = true;
          if (checkSala !== "NETWORK ERROR") {
            const checkSave = await saveReserva(data);
            if (checkSave === "NETWORK ERROR") toastBigError("Error de conexión!");
            else {
              if (checkSala.requiereConfirmacion && !user.profile.isAdmin) toastBigInfo("Requiere Confirmación", "Tu reserva requiere confirmación por parte del administrador. Recibirás una notificación cuando esté confirmada.");
              else toastBigSuccess("Reserva Confirmada!", "Tu reserva está confirmada!");
            }
          } else toastBigError("Error de conexión!");
        } catch (e) {}
      }
    }
    setRefresh(!refresh);
    fetchReservas();
  };

  const eventShow = (idReserva) => {
    const reservaAux = reservas.find((obj) => {
      return obj._id === idReserva;
    });
    setReserva(reservaAux);
    if (reservaAux.user._id === user._id) setEsPropia(true);
    else setEsPropia(false);
    setSala({ label: reservaAux.sala, value: reservaAux.sala });
    setNotice(true);
  };

  // const eventCancel = (idReserva) => {
  //   const reservaAux = reservas.find((obj) => {
  //     return obj._id === idReserva;
  //   });
  //   setReserva(reservaAux);
  //   setSala({ label: reservaAux.sala, value: reservaAux.sala });
  //   setShowCancel(true);
  //   // handleCancelarReserva();
  // };

  // const eventConfirm = (idReserva) => {
  //   const reservaAux = reservas.find((obj) => {
  //     return obj._id === idReserva;
  //   });
  //   setReserva(reservaAux);
  //   setSala({ label: reservaAux.sala, value: reservaAux.sala });
  //   handleConfirmarReserva();
  // };

  const cancelarReserva = async (caso) => {
    const cacheReserva = { ...reserva };
    switch (caso) {
      case "unica":
        cacheReserva.cancelada = true;
        cacheReserva.cancela = user;
        try {
          await saveReserva(cacheReserva);
          setNotice(false);
          setRefresh(!refresh);
          fetchReservas();
        } catch (e) {}
        break;
      case "single":
        const excepcion = {
          reserva: cacheReserva._id,
          start: cacheReserva.start,
          cancela: user,
        };
        try {
          await saveExcepcion(excepcion);
          setNotice(false);
          setRefresh(!refresh);

          fetchReservas();
        } catch (e) {}
        break;
      case "todas":
        const origReserva = await getReserva(cacheReserva._id);
        origReserva.cancelada = true;
        origReserva.cancela = user;
        try {
          await saveReserva(origReserva);
          setNotice(false);
          setRefresh(!refresh);

          fetchReservas();
        } catch (e) {}
        break;
    }
  };

  const handleCancelarReserva = async () => {
    if (!reserva.semanal) {
      toastConfirm("¿Cancelar Reserva?", "", () => cancelarReserva("unica"));
    } else {
      toastTriState(
        "¿Cancelar Reserva?",
        "Todos las reservas",
        "Sólo esta fecha",
        "Conservar Reserva",
        () => cancelarReserva("todas"),
        () => cancelarReserva("single")
      );
    }
    setRefresh(!refresh);
  };

  const handleConfirmarReserva = async () => {
    toastConfirm("Confirmar Reserva?", "", () => confirmarReserva());
    setRefresh(!refresh);
  };

  const confirmarReserva = async () => {
    const cacheReserva = { ...reserva };
    cacheReserva.confirmada = true;
    cacheReserva.cancelada = false;
    try {
      await confirmaReserva(cacheReserva);
      setNotice(false);
      setRefresh(!refresh);
      fetchReservas();
    } catch (e) {}
  };

  // design html for event tile
  // const EventComponent =
  //   ({ events, change }) =>
  //   (props) => {
  //     return (
  //       <div className="customEventTile" title="This is EventTile">
  //         <h5>{props.event.title}</h5>
  //         <button onClick={props.change}>Do Something</button>
  //       </div>
  //     );
  //   };

  const getPagedData = () => {
    let filtered = [];
    if (searchQuery.trim().length > 0) {
      filtered = usuarios.filter((m) => cleanString(m.apellido).includes(cleanString(searchQuery)) || cleanString(m.nombre).includes(cleanString(searchQuery)));
    } else {
      filtered = usuarios;
    }
    const records = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    // const records = paginate(ordered, currentPage, pageSize);
    return { totalCount: filtered.length, records };
  };

  const eventNotify = (idReserva) => {
    setReserva(
      reservas.find((obj) => {
        return obj._id === idReserva;
      })
    );
    // console.log(reserva);
    setSala({ label: reserva.sala, value: reserva.sala });
    setupNotificar();
  };

  const setupNotificar = async () => {
    if (timer) clearInterval(timer);
    try {
      const notificar = [];
      const usuarios = await getUsers();
      usuarios.forEach((usuario) => {
        if (usuario._id !== user._id) {
          const item = {
            _id: usuario._id,
            nombre: usuario.nombre,
            apellido: usuario.apellido,
            celular: usuario.celular,
            team: usuario.team,
            tipoAgente: usuario.tipoAgente,
            selected: false,
          };
          notificar.push(item);
        }
      });
      setSearchQuery("");
      setSelectTeam(false);
      setUsuarios(notificar);
      setNotify(true);
    } catch (e) {
      toastBigError("Error de Conexion");
    }
  };

  const setTeam = (event, value) => {
    setSelectTeam(value);
    usuarios.forEach((usuario, index) => {
      if (usuario.team || usuario.tipoAgente === "Team Leader") usuarios[index].selected = value;
    });
  };

  const changeSelected = (event, value, index) => {
    const cacheUsuarios = [...records];
    cacheUsuarios[index].selected = !cacheUsuarios[index].selected;
    setUsuarios(cacheUsuarios);
  };

  const handleNotify = async () => {
    const cacheUsuarios = [...usuarios];
    const filtered = cacheUsuarios.filter((usuario) => usuario.selected);
    // cacheUsuarios.forEach((usuario, index) => {
    //   if (!usuario.selected) cacheUsuarios.splice(index, 1);
    // });
    const data = {
      reserva: reserva,
      sala: sala,
      usuarios: filtered,
      user: user,
    };
    if (filtered.length > 0) {
      const response = await notifyReserva(data);
      toastInfo("Notificado(s)!");
    }
  };

  const sendWhatsapp = (phone, nombre) => {
    var txtMessage = "";
    if (nombre) txtMessage = WAPP_NOTIFY_AGENTE;
    else txtMessage = WAPP_NOTIFY;

    let dia = moment(reserva.start).format("dddd D [de] MMMM [de] YYYY");
    let comienzo = moment(reserva.start).format("HH:mm");
    let dow = moment(reserva.start).format("dddd");
    let fin = moment(reserva.end).format("HH:mm");
    txtMessage = txtMessage.replaceAll("#SALA", reserva.sala.descripcion);
    txtMessage = txtMessage.replaceAll("#RECEPTOR", nombre);
    txtMessage = txtMessage.replaceAll("#USUARIO", reserva.user.nombre + " " + reserva.user.apellido);
    txtMessage = txtMessage.replaceAll("#TITULO", reserva.titulo);
    txtMessage = txtMessage.replaceAll("#DETALLE", reserva.detalle);
    txtMessage = txtMessage.replaceAll("#DIA", dia);
    txtMessage = txtMessage.replaceAll("#COMIENZO", comienzo);
    txtMessage = txtMessage.replaceAll("#FIN", fin);
    txtMessage = txtMessage.replaceAll("#DOW", dow);
    if (!reserva.semanal) {
      let arrTexto = txtMessage.split("@SEMANAL");
      txtMessage = arrTexto[0] + arrTexto[2];
      txtMessage = txtMessage.replaceAll("@NOSEMANAL", "");
    } else {
      let arrTexto = txtMessage.split("@NOSEMANAL");
      txtMessage = arrTexto[0] + arrTexto[2];
      txtMessage = txtMessage.replaceAll("@SEMANAL", "");
    }

    const destino = "https://api.whatsapp.com/send?&phone=" + phone + "&text=" + encodeURIComponent(txtMessage) + "&app_absent=0";
    // window.location.href = destino;
    window.open(destino);
  };

  const { records } = getPagedData();
  return (
    <>
      {view === "calendar" && salas && (
        <Row className="p-0 mt-2">
          <Col className="p-0" xs="12">
            <Select
              isSearchable={false}
              className="react-select react-select-default"
              placeholder="Seleccione Sala"
              classNamePrefix="react-select"
              name="sala"
              value={sala}
              onChange={(selectedOption, input) => handleChangeSala(selectedOption, input)}
              options={salas}
            />
          </Col>
        </Row>
      )}

      {view === "calendar" && sala && reservas && (
        <Row className="p-0">
          <Col lg="12" md="12" className="p-0">
            <Calendar
              selectable
              views={views}
              defaultView={Views.DAY}
              dayLayoutAlgorithm={dayLayoutAlgorithm}
              // defaultDate={currentDate}
              events={reservas}
              onSelectSlot={handleSelectSlot}
              onNavigate={handleNavigation}
              timeslots={2}
              onSelectEvent={handleSelectEvent}
              step={30}
              localizer={mLocalizer}
              eventPropGetter={eventStyleGetter}
              messages={{
                week: "Semana",
                // work_week: "Semana de trabajo",
                work_week: "Semana",
                day: "Día",
                month: "Mes",
                previous: "Atrás",
                next: "Después",
                today: "Hoy",
                agenda: "Agenda",
                // showMore: (total) => `+${total} más`,
              }}
              // scrollToTime={scrollToTime}
              // start time 8:00am
              min={new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 9)}
              // end time 5:00pm
              max={new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 19)}
              components={{
                // event: EventComponent({ events, change }),
                //reserva as events array and change is custom method passed into component(for perform any functionality on parent state)
                toolbar: CustomToolbar({ events, change }),
              }}
            />
          </Col>
        </Row>
      )}

      {view === "list" && reservas && (
        <Row>
          <Col className="ml-auto mr-auto mt-4" sm="12">
            <Table responsive>
              <thead>
                <tr>
                  {/* <th>
                    <p>
                      <strong>Sala</strong>
                    </p>
                  </th> */}
                  <th>
                    <p>
                      <strong>Título</strong>
                    </p>
                  </th>
                  <th>
                    <p>
                      <strong>Día</strong>
                    </p>
                  </th>
                  <th>
                    <p>
                      <strong>Horario</strong>
                    </p>
                  </th>
                  <th>
                    <p>
                      <strong>Reservada Por</strong>
                    </p>
                  </th>
                  <th>
                    <p>
                      <strong>Sala</strong>
                    </p>
                  </th>
                  <th></th>
                  {/* <th></th> */}
                </tr>
              </thead>
              <tbody>
                {reservas.map((reserva) => (
                  <tr key={Math.random()}>
                    {/* <td className="clickable" onClick={() => eventShow(reserva._id)}>
                      {reserva.sala}
                    </td> */}
                    <td className="clickable" onClick={() => eventShow(reserva._id)}>
                      {reserva.titulo}
                    </td>
                    <td className="clickable" onClick={() => eventShow(reserva._id)}>
                      {capFirst(moment(reserva.start).format("dddd D [de] MMMM "))}
                    </td>
                    <td className="clickable" onClick={() => eventShow(reserva._id)}>
                      {capFirst(moment(reserva.start).format("HH:mm")) + "  - " + capFirst(moment(reserva.end).format("HH:mm"))}
                    </td>
                    <td className="clickable" onClick={() => eventShow(reserva._id)}>
                      {/* {reserva.user.apellido + " " + reserva.user.nombre} */}
                      {reserva.user.nombre + " " + reserva.user.apellido}
                    </td>
                    <td className="clickable" onClick={() => eventShow(reserva._id)}>
                      {reserva.sala}
                    </td>
                    <td>
                      {reserva.cancelada && (
                        <Badge className="mr-1" color="danger">
                          Cancelada
                        </Badge>
                      )}
                      {reserva.user._id !== user._id && !reserva.cancelada && reserva.confirmada && (
                        <Badge className="mr-1" color="info">
                          Notificado
                        </Badge>
                      )}
                      {reserva.user._id === user._id && reserva.confirmada && !reserva.cancelada && (
                        <Badge className="mr-1" color="success">
                          Confirmada
                        </Badge>
                      )}
                      {(reserva.user._id === user._id || (reserva.user._id !== user._id && user.profile.isAdmin)) && !reserva.confirmada && !reserva.cancelada && (
                        <Badge className="mr-1" color="warning">
                          Pendiente
                        </Badge>
                      )}
                    </td>
                    {/* <td>
                      {user.profile.isAdmin && reserva.user._id !== user._id && !reserva.cancelada && (
                        <>
                          <Button className="btn-just-icon mr-1" disabled={reserva.confirmada} color="success" size="sm" type="button" onClick={() => eventConfirm(reserva._id)}>
                            <i className="nc-icon nc-check-2" />
                          </Button>
                          <Button className="btn-just-icon mr-1" color="danger" size="sm" type="button" onClick={() => eventCancel(reserva._id)}>
                            <i className="nc-icon nc-simple-remove" />
                          </Button>
                        </>
                      )}
                      {reserva.user._id === user._id && !reserva.cancelada && (
                        <>
                          <Button className="btn-just-icon mr-1" color="info" disabled={!reserva.confirmada} size="sm" type="button" onClick={() => eventNotify(reserva._id)}>
                            <i className="nc-icon nc-send" />
                          </Button>
                          <Button className="btn-just-icon mr-1" color="success" disabled={!reserva.confirmada} size="sm" type="button">
                            <i className="fa fa-whatsapp" />
                          </Button>
                          <Button className="btn-just-icon mr-1" color="danger" size="sm" type="button" onClick={() => eventCancel(reserva._id)}>
                            <i className="nc-icon nc-simple-remove" />
                          </Button>
                        </>
                      )}
                    </td> */}
                  </tr>
                ))}
                {/* <tr>
                  <td className="text-center">1</td>
                  <td>Andrew Mike</td>
                  <td>Develop</td>
                  <td>2013</td>
                  <td className="text-right">€ 99,225</td>
                  <td className="td-actions text-right">
                    <Button className="btn-link mr-1" color="info" data-toggle="tooltip" id="tooltip542628903" size="sm" type="button">
                      <i className="fa fa-user" />
                    </Button>
                    <UncontrolledTooltip delay={0} placement="top" target="tooltip542628903">
                      View Profile
                    </UncontrolledTooltip>
                    <Button className="btn-link mr-1" color="success" data-toggle="tooltip" id="tooltip278266693" size="sm" type="button">
                      <i className="fa fa-edit" />
                    </Button>
                    <UncontrolledTooltip delay={0} placement="top" target="tooltip278266693">
                      Edit Profile
                    </UncontrolledTooltip>
                    <Button className="btn-link" color="danger" data-toggle="tooltip" id="tooltip16493734" size="sm" type="button">
                      <i className="fa fa-times" />
                    </Button>
                    <UncontrolledTooltip delay={0} placement="top" target="tooltip16493734">
                      Remove
                    </UncontrolledTooltip>
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}

      <Modal isOpen={modal} backdrop="static" toggle={() => setModal(false)}>
        <div className="modal-header">
          {/* <button className="close" type="button" onClick={() => setModal(false)}>
            <span>×</span>
          </button> */}

          <h5 className="modal-title text-center">
            <strong>Reservar Sala</strong>
          </h5>
        </div>
        <div className="modal-body" style={{ padding: "0px", marginLeft: "20px", marginRight: "20px" }}>
          <Row className="mb-1 justify-content-center">
            <h6>{reserva.label}</h6>
          </Row>
          <Row className="mb-1">
            <InputGroup className={errors["titulo"] ? "has-danger align-items-center" : " align-items-center"}>
              <h6>
                <Label className="mb-0" style={{ marginRight: "20px" }}>
                  Titulo
                </Label>
              </h6>
              <Input type="text" name="titulo" onChange={(event, otro) => handleChange(event, otro)} className={errors["titulo"] ? "text-control-danger force-border" : "force-border"} />
            </InputGroup>
          </Row>
          <Row className="mb-1 align-items-center">
            <InputGroup className="date align-items-center">
              <h6>
                <Label className="mb-0" style={{ marginRight: "22px" }}>
                  Desde
                </Label>
              </h6>
              <ReactDatetime
                dateFormat={false}
                timeConstraints={{ minutes: { min: 0, max: 59, step: 30 }, hours: { min: 9, max: 19, step: 1 } }}
                value={reserva.start}
                onChange={(event) => handleChangeTime(event)}
                inputProps={{
                  className: "form-control",
                }}
              />
              <InputGroupAddon addonType="append" style={{ height: "40px" }}>
                <InputGroupText>
                  <span className="glyphicon glyphicon-calendar">
                    <i className="fa fa-clock-o" />
                  </span>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Row>
          <Row className="mb-1">
            <InputGroup className="date align-items-center">
              <h6>
                <Label className="mb-0" style={{ marginRight: "23px" }}>
                  Hasta
                </Label>
              </h6>
              <ReactDatetime
                dateFormat={false}
                timeConstraints={{ minutes: { min: 0, max: 59, step: 30 }, hours: { min: 9, max: 19, step: 1 } }}
                value={reserva.end}
                onChange={(event) => handleChangeTime(event)}
                inputProps={{
                  className: "form-control",
                }}
              />
              <InputGroupAddon addonType="append" style={{ height: "40px" }}>
                <InputGroupText>
                  <span className="glyphicon glyphicon-calendar">
                    <i className="fa fa-clock-o" />
                  </span>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Row>
          <Row className="mb-1">
            <InputGroup className={errors["detalle"] ? "has-danger align-items-center" : " align-items-center"}>
              <h6>
                <Label className="mb-0 mr-2">Cant.Participantes</Label>
              </h6>
              <Input type="text" name="detalle" value={reserva.detalle} onChange={(event) => handleChange(event)} className={errors["detalle"] ? "text-control-danger force-border" : "force-border"} />
            </InputGroup>
          </Row>
          <Row className="mb-1">
            <InputGroup className="align-items-center">
              <h6>
                <Label className="mb-0 mr-2">Repite semanalmente</Label>
              </h6>
              <Switch onColor="success" offColor="default" onText="" offText="" name="semanal" value={reserva.semanal} onChange={() => handleChangeSemanal()} />
            </InputGroup>
          </Row>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button className="btn-link" color="success" type="button" onClick={handleReserva}>
              Reservar
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => {
                setRefresh(!refresh);
                setModal(false);
              }}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>

      <Modal isOpen={notice && reserva !== null} backdrop="static" toggle={() => setNotice(false)}>
        <div className="modal-header no-border-header">
          <button
            className="close"
            type="button"
            onClick={() => {
              setRefresh(!refresh);

              setNotice(false);
            }}
          >
            ×
          </button>
        </div>
        <div className="modal-body">
          {reserva.user && !esPropia && (
            <Row className="justify-content-center">
              <h3 className="mt-0">
                <strong>{reserva.user.nombre + " " + reserva.user.apellido}</strong>
              </h3>
            </Row>
          )}
          {reserva.sala && (
            <Row className="justify-content-center">
              <h5 className="mt-0 mb-0">{reserva.sala.descripcion}</h5>
            </Row>
          )}
          <Row className="justify-content-center">
            <h4 className="mt-2">
              <strong>{reserva.title}</strong>
            </h4>
          </Row>
          <Row className=" justify-content-center">
            <h6 className="mb-0">{capFirst(moment(reserva.start).format("dddd D [de] MMMM [de] YYYY"))}</h6>
          </Row>
          <Row className="justify-content-center">
            <h6 className="mb-0">
              {capFirst(moment(reserva.start).format("HH:mm"))} - {capFirst(moment(reserva.end).format("HH:mm"))}
            </h6>
          </Row>
          <Row className="justify-content-center">
            <p className="mt-1 mb-0 text-center">{reserva.detalle}</p>
          </Row>
          {!reserva.confirmada && (
            <div className="info">
              <div className="icon icon-primary">
                <i className="nc-icon nc-alert-circle-i" />
              </div>
              {esPropia ? "Tu" : "La"} reserva aún no está confirmada
            </div>
          )}
          {reserva.confirmada && (
            <div className="info">
              <div className="icon icon-success">
                <i className="nc-icon nc-check-2" />
              </div>
              {esPropia ? "Tu" : "Esta"} reserva está confirmada
            </div>
          )}
          {/* {reserva.confirmada && !esPropia && (
            <div className="info">
              <div className="icon icon-success">
                <i className="nc-icon nc-check-2" />
              </div>
              Esta reserva está confirmada
            </div>
          )} */}
          {esPropia && (
            <Row className="mb-1 justify-content-center">
              <Button className="btn-magnify btn-round  mr-1" color="danger" onClick={() => handleCancelarReserva()}>
                <i className="nc-icon nc-simple-remove mr-1" />
                Cancelar
              </Button>
              <Button className="btn-magnify btn-round  mr-1" disabled={!reserva.confirmada} color="info" onClick={setupNotificar}>
                <i className="nc-icon nc-send mr-1" />
                Notificar
              </Button>
              <Button className="btn-magnify btn-round  mr-1" disabled={!reserva.confirmada} color="success" onClick={() => sendWhatsapp("")}>
                <i className="fa fa-whatsapp mr-1" />
                Invitar
              </Button>
            </Row>
          )}
          {!esPropia && user.profile.isAdmin && (
            <Row className="mb-1 justify-content-center">
              <Button className="btn-magnify btn-round  mr-1" color="danger" onClick={() => handleCancelarReserva()}>
                <i className="nc-icon nc-simple-remove mr-1" />
                Cancelar
              </Button>
              <Button className="btn-magnify btn-round  mr-1" color="success" disabled={reserva.confirmada} onClick={() => handleConfirmarReserva()}>
                <i className="nc-icon nc-check-2 mr-1" />
                Confirmar
              </Button>
            </Row>
          )}
        </div>
        <div className="modal-footer">
          <Button
            className="btn-link"
            color="primary"
            type="button"
            onClick={() => {
              setRefresh(!refresh);

              setNotice(false);
            }}
          >
            Aceptar
          </Button>
        </div>
      </Modal>

      <Modal isOpen={notify && reserva != null} backdrop="static" toggle={() => setNotify(false)}>
        <div className="modal-header no-border-header">
          <button className="close" type="button" onClick={() => setNotify(false)}>
            ×
          </button>
          {user.team ||
            (user.tipoAgente === "Team Leader" && (
              <>
                Seleccionar mi Team <Switch className="mb-0" onColor="success" onText="" offText="" value={selectTeam} onChange={(event, value) => setTeam(event, value)} />
              </>
            ))}
          <InputGroup>
            <Input type="text" name="searchQuery" placeholder="Buscar..." value={searchQuery} onChange={handleSearchQuery} />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i aria-hidden={true} className="fa fa-search" />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div
          className="modal-body"
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            maxHeight: "60vh",
            overflowY: "auto",
          }}
        >
          {records &&
            records.map((usuario, index) => (
              <Row key={Math.random()} className="align-items-center mb-1">
                {/* <Col>{usuario.apellido + " " + usuario.nombre}</Col> */}
                <Col xs="6">{usuario.nombre + " " + usuario.apellido}</Col>
                <Col xs="3">
                  <Switch className="mb-0" onColor="success" onText="" offText="" value={usuario.selected} onChange={(event, value) => changeSelected(event, value, index)} />
                </Col>
                <Col xs="3">
                  <Button className="btn-just-icon btn-whatsapp " data-number={usuario.celular} data-nombre={usuario.nombre} onClick={() => sendWhatsapp(usuario.celular, usuario.nombre)}>
                    <i className="fa fa-whatsapp" />
                  </Button>
                </Col>
              </Row>
            ))}
        </div>
        <div className="modal-footer">
          <Button className="btn-link" color="primary" type="button" onClick={handleNotify}>
            Notificar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default EventCalendar;
