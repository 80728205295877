import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Container, Col, Row, Button, Table, Badge } from "reactstrap";
import { toastBigError, toastBigSuccess } from "components/utils/toasts";
import { saveInvitacion, getInvitaciones, resendQR } from "services/invitacionesService";
import { getConfig } from "config/config";
import { cleanString } from "components/utils/cleanString";
import { paginate } from "components/utils/paginate";
import PaginationControls from "components/PaginationControls/paginationControls";
// import Joi from "joi-browser";
const { WAPP_FIESTA_TEXT, WAPP_FIESTA_LINK, WAPP_FIESTA_ACOMPANANTE_TEXT } = require("../constants/constants");

const Invitar = (props) => {
  const { user } = props;

  const [mounted, setMounted] = useState(false);
  const [allRecords, setAllRecords] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState({ path: "contacto", order: "asc" });

  const config = getConfig();
  // const pageSize = config.recordsPerPage;
  const pageSize = 2000;

  const fetchData = async () => {
    const response = await getInvitaciones();
    setAllRecords(response);
    setMounted(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (!mounted) fetchData();
  }, []);

  const getPagedData = () => {
    let filtered = [];
    if (searchQuery.trim().length > 0) {
      filtered = allRecords.filter((m) => cleanString(m.nombre).includes(cleanString(searchQuery)));
    } else {
      filtered = allRecords;
    }
    const ordered = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const records = paginate(ordered, currentPage, pageSize);
    return { totalCount: filtered.length, records };
  };

  const { totalCount, records } = getPagedData();
  if (currentPage > Math.ceil(totalCount / pageSize) && totalCount > 0) setCurrentPage(Math.ceil(totalCount / pageSize));

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleAcompanante = async (invitacion) => {
    // console.log("invitar acompanante");
    // console.log(invitacion);
    const telefono = invitacion.telefono;
    const data = { contacto: invitacion.contacto, acompanante: true };
    const response = await saveInvitacion(data);
    sendWhatsapp(telefono, response.data.hash, true);
    toastBigSuccess("Enviado!", "Invitación enviada correctamente!");
    fetchData();
  };

  // it's asynchronous as we have to wait for the contact selection
  async function handleGetContacts() {
    // const supported = "contacts" in navigator;
    // if (supported) {
    if (navigator.contacts) {
      const props = ["name", "tel"];
      const opts = { multiple: false };
      try {
        const contacts = await navigator.contacts.select(props, opts);
        if (contacts.length > 0 && contacts[0].tel && contacts[0].tel.length > 0) {
          let contacto = contacts[0].name[0];
          let telefono = contacts[0].tel[0];
          telefono = telefono.replace(/ /g, "");
          const data = { contacto: contacto, telefono: telefono };
          const response = await saveInvitacion(data);
          sendWhatsapp(telefono, response.data.hash);
          toastBigSuccess("Enviado!", "Invitación enviada correctamente!");
          fetchData();
        }
      } catch (ex) {
        console.log(ex);
        toastBigError("Error", "No se pudo enviar la invitación");
      }
    } else {
      toastBigError("Error", "No se puede acceder a la agenda de contactos");
      // try {
      //   const data = { contacto: "leo", telefono: "4208297" };
      //   const telefono = data.telefono;
      //   const response = await saveInvitacion(data);
      //   sendWhatsapp(telefono, response.data.hash);
      //   toastBigSuccess("Enviado!", "Invitación enviada correctamente!");
      //   fetchData();
      // } catch (ex) {
      //   console.log(ex);
      // }
    }
  }

  const resendWhatsapp = (invitacion) => {
    sendWhatsapp(invitacion.telefono, invitacion.hash);
  };

  const resendMail = async (invitacion) => {
    const data = { token: invitacion.hash };
    try {
      const response = await resendQR(data);
      console.log(response);
      toastBigSuccess("Reenviado!", "Invitación con QR enviada nuevamente!");
    } catch (ex) {
      console.log(ex);
    }
  };

  const sendWhatsapp = (phone, hash, esAcompanante = false) => {
    if (!esAcompanante) var txtMessage = WAPP_FIESTA_TEXT;
    else var txtMessage = WAPP_FIESTA_ACOMPANANTE_TEXT;
    txtMessage += "  " + WAPP_FIESTA_LINK + "?token=" + hash;

    const destino = "https://api.whatsapp.com/send?&phone=" + phone + "&text=" + encodeURIComponent(txtMessage) + "&app_absent=0";

    var linkElement = document.createElement("a");
    linkElement.id = "link";
    window.document.body.appendChild(linkElement);

    var link = document.getElementById("link");
    link.setAttribute("href", destino);
    // link.setAttribute("target", "_blank");
    link.click();
    window.document.body.removeChild(linkElement);
  };

  const labelPages = Math.ceil(totalCount / pageSize) > 1 ? currentPage + "/" + Math.ceil(totalCount / pageSize) : "";

  return (
    <>
      <Container className="wrapper">
        {/* <form onSubmit={handleSubmit}> */}
        <h3 className="mt-4 mb-2">Enviar Invitaciones</h3>
        {/* <Row>
            <Col sm="12">
              <FormGroup className={errors["email"] ? "has-danger" : ""}>
                <Input autoFocus className={errors["email"] ? "text-control-danger" : ""} placeholder="Dirección de correo" type="text" name="email" value={data.email} onChange={(event) => handleChange(event)} />
                {errors["email"] ? <div className="form-control-feedback">{errors["email"]}</div> : ""}
              </FormGroup>
            </Col>
          </Row> */}
        <Row className="justify-content-center mt-3">
          {/* <Button outline className="btn-round mr-1" color="success" type="button" onClick={handleSubmit}>
              Enviar
            </Button> */}
          <Button className="btn-round mr-1 blue" color="success" type="button" onClick={handleGetContacts}>
            Invitar
          </Button>
        </Row>

        {/* </form> */}
        <Row>
          <h5>Mis Invitaciones</h5>
        </Row>
        <Row>
          <Table hover responsive striped className="negrita">
            <thead>
              <tr>
                <th style={{ width: "35%" }}>Nombre</th>
                <th></th>
                <th style={{ width: "35%" }}>Acompañante</th>
                <th style={{ width: "10%" }}></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {records.map((invitacion) => (
                <tr key={Math.random()}>
                  <td>
                    <span className="black">{invitacion.contacto.toUpperCase()}</span>
                  </td>
                  <td>
                    <i class="fa fa-2x fa-whatsapp" onClick={() => resendWhatsapp(invitacion)}></i>
                  </td>
                  <td>
                    {/* <span className="black">{invitacion.telefono}</span> */}
                    {/* <strong>3</strong>{" "} */}
                    {!invitacion.acompanante ? (
                      <Button className="btn-sm" onClick={() => handleAcompanante(invitacion)}>
                        + Agregar
                      </Button>
                    ) : (
                      "ACOMPAÑANTE"
                    )}
                  </td>
                  <td>
                    {invitacion.confirmo ? (
                      <Badge className="mr-1" color="success" pill>
                        Acreditado
                      </Badge>
                    ) : (
                      <Badge className="mr-1" color="danger" pill>
                        Sin Acreditar
                      </Badge>
                    )}
                  </td>
                  <td>{invitacion.confirmo && <i class="fa fa-2x fa-qrcode" onClick={() => resendMail(invitacion)}></i>}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Container>
            <Row className="justify-content-between align-items-center">
              <span>{labelPages}</span>
              <PaginationControls onPageChange={(page) => handlePageChange(page)} itemsCount={totalCount} pageSize={pageSize} currentPage={currentPage} />
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
};

export default Invitar;
