const TIPO_AGENTE = [
  // { value: "", label: " Choose city", isDisabled: true },
  { value: "Junior", label: "Junior" },
  { value: "Rapp", label: "Rapp" },
  { value: "Alto", label: "Alto" },
  { value: "Puro", label: "Puro" },
  { value: "Team Leader", label: "Team Leader" },
];

let wappNotify = `@SEMANALHola! #USUARIO te notifica de las reuniones que se realizarán los #DOW `;
// wappNotify += `de #COMIENZO a #FIN en la sala #SALA, para desarrollar: #DETALLE.`;
wappNotify += `de #COMIENZO a #FIN.`;
wappNotify += ` Te esperamos en nuestras oficinas en calle 473 Bis esq. 13b de City Bell,`;
wappNotify += ` teléfono 221 480-1450. Esperamos tu confirmación.@SEMANAL`;
wappNotify += `@NOSEMANALHola! #USUARIO te notifica de la reunión que se realizará el día #DIA `;
// wappNotify += `de #COMIENZO a #FIN en la sala #SALA, para desarrollar: #DETALLE.`;
wappNotify += `de #COMIENZO a #FIN.`;
wappNotify += ` Te esperamos en nuestras oficinas en calle 473 Bis esq. 13b de City Bell,`;
wappNotify += ` teléfono 221 480-1450. Esperamos tu confirmación.@NOSEMANAL`;
let wappNotifyAgente = `@SEMANALHola #RECEPTOR! #USUARIO te notifica de las reuniones que se realizarán los días #DOW `;
// wappNotifyAgente += `de #COMIENZO a #FIN en la sala #SALA, para desarrollar: #DETALLE.@SEMANAL`;
wappNotifyAgente += `de #COMIENZO a #FIN.`;
wappNotifyAgente += `@NOSEMANALHola #RECEPTOR! #USUARIO te notifica de la reunión que se realizará el día #DIA `;
// wappNotifyAgente += `de #COMIENZO a #FIN en la sala #SALA, para desarrollar: #DETALLE@NOSEMANAL.`;
wappNotifyAgente += `de #COMIENZO a #FIN.`;

let wappFiestaLink = "http://eventosremax.synodos.com.ar/fiesta.html";
let wappFiestaText = "Hola, cómo estás? Te paso un link para que puedas completar tus datos para acreditarte en nuestra ";
wappFiestaText += "fiesta, y recibir el QR para ingresar a la misma. ESTE LINK ES PERSONAL, NO LO COMPARTAS!";
let wappFiestaAcompananteText = "Hola, cómo estás? Te envío un nuevo link para que puedas sumar a un acompañante a la fiesta, ";
wappFiestaAcompananteText += " y reciba el QR para ingresar a la misma. ";

const WAPP_NOTIFY = wappNotify;
const WAPP_NOTIFY_AGENTE = wappNotifyAgente;
const WAPP_FIESTA_LINK = wappFiestaLink;
const WAPP_FIESTA_TEXT = wappFiestaText;
const WAPP_FIESTA_ACOMPANANTE_TEXT = wappFiestaAcompananteText;

exports.TIPO_AGENTE = TIPO_AGENTE;

exports.WAPP_NOTIFY = wappNotify;
exports.WAPP_NOTIFY_AGENTE = wappNotifyAgente;
exports.WAPP_FIESTA_LINK = wappFiestaLink;
exports.WAPP_FIESTA_TEXT = wappFiestaText;
exports.WAPP_FIESTA_ACOMPANANTE_TEXT = wappFiestaAcompananteText;
