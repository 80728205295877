import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import _ from "lodash";

import { getInvitados } from "services/invitacionesService";
import { Container, Button, Row } from "reactstrap";

const Invitados = (props) => {
  const { user } = props;

  const [mounted, setMounted] = useState(false);
  const [invitados, setInvitados] = useState([]);

  const fetchData = async () => {
    let lista = [];
    const response = await getInvitados();
    response.map((invitado) => {
      lista.push({
        nombre: invitado.confirmo ? invitado.apellido + " " + invitado.nombre : invitado.contacto,
        dni: invitado.dni,
        telefono: invitado.telefono,
        email: invitado.email,
        acreditado: invitado.confirmo ? "Si" : "No",
        ingreso: invitado.asistio ? "Sí" : "No",
        invito: invitado.user.nombre + " " + invitado.user.apellido,
      });
    });
    setInvitados(lista);
    setMounted(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (!mounted) fetchData();
  }, []);

  const handleXLS = (acreditados, csv = false) => {
    let lista = [];
    console.log(invitados);
    // if (acreditados) lista = invitados.filter((item) => item.acreditado === "Si" && item.invito !== "Daniella Levine");
    if (acreditados) lista = invitados.filter((item) => item.acreditado === "Si");
    else lista = invitados;
    lista.sort((a, b) => {
      const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
      const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    const worksheet = XLSX.utils.json_to_sheet(lista);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invitados");
    XLSX.utils.sheet_add_aoa(worksheet, [["Nombre", "DNI", "Teléfono", "e-mail", "Acreditado", "Ingresó", "Invitado Por"]], { origin: "A1" });

    // let objectMaxLength = [];
    // const [startLetter, endLetter] = worksheet["!ref"]?.replace(/\d/, "").split(":");
    // const ranges = _.range(startLetter.charCodeAt(0), endLetter.charCodeAt(0) + 1);
    // ranges.forEach((c) => {
    //   const cell = String.fromCharCode(c);
    //   const cellLength = worksheet[`${cell}1`].v.length + 1;
    //   objectMaxLength.push({ wch: cellLength });
    // });
    // console.log(objectMaxLength);
    // worksheet["!cols"] = objectMaxLength;
    // worksheet["!cols"] = objectMaxLength;
    worksheet["!cols"] = [{ wch: 50 }, { wch: 10 }, { wch: 15 }, { wch: 30 }, { wch: 10 }, { wch: 10 }, { wch: 40 }];

    // if (!worksheet["!cols"]) worksheet["!cols"] = [];
    // let max_width = invitados.reduce((w, r) => Math.max(w, r.nombre.length), 5);
    // worksheet["!cols"][2] = [{ wch: max_width }];
    // max_width = invitados.reduce((w, r) => Math.max(w, r.dni.length), 5);
    // worksheet["!cols"][2] = [{ wch: max_width }];
    // max_width = invitados.reduce((w, r) => Math.max(w, r.telefono.length), 5);
    // worksheet["!cols"][3] = [{ wch: max_width }];
    // max_width = invitados.reduce((w, r) => Math.max(w, r.email.length), 5);
    // worksheet["!cols"][4] = [{ wch: max_width }];
    // max_width = invitados.reduce((w, r) => Math.max(w, r.confirmo.length), 5);
    // worksheet["!cols"][5] = [{ wch: max_width }];
    // max_width = invitados.reduce((w, r) => Math.max(w, r.invito.length), 5);
    // worksheet["!cols"][6] = [{ wch: max_width }];
    if (!csv) XLSX.writeFile(workbook, "invitados.xlsx", { compression: true });
    else XLSX.writeFile(workbook, "invitados.csv", { bookType: "csv", FS: "," });
  };

  return (
    <>
      <Container className="wrapper">
        <h3 className="mt-4 mb-2">Descargar Lista</h3>
        <Row className="justify-content-center mt-3">
          <Button disabled={invitados.length === 0} className="btn-round mr-1 blue" color="success" type="button" onClick={() => handleXLS(true)}>
            Solo Acreditados (EXCEL)
          </Button>
          <Button disabled={invitados.length === 0} className="btn-round mr-1 blue" color="success" type="button" onClick={() => handleXLS(true, true)}>
            Solo Acreditados (CSV)
          </Button>
          <Button disabled={invitados.length === 0} className="btn-round mr-1 blue" color="success" type="button" onClick={() => handleXLS(false)}>
            Todos
          </Button>
        </Row>
      </Container>
    </>
  );
};

export default Invitados;
